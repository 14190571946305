<template>
    <div>
        <div class="row align-items-center justify-content-center" v-if="ajax_call_in_progress">
            <div class="col-lg-12 py-5 px-3 mt-3">
                <div class="d-flex align-items-center justify-content-center">
                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                </div>
            </div>
        </div>
        <div class="pr-2" v-else>
            <div class="h-100 overflow-auto bottom-padding ml-2 mr-2">
                <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                    <div class="breadcrumbactive">
                        <div>
                            <span class="pointer" @click="goToListView" v-if="$route.path.startsWith('/bankers/')">Bankers</span>
                            <span class="pointer" @click="goToListView" v-else-if="$route.path.startsWith('/suppliers/')">Suppliers</span>
                            <span class="pointer" @click="goToListView" v-else-if="$route.path.startsWith('/investors/')">Investors</span>
                            <span class="pointer" @click="goToListView" v-else-if="$route.path.startsWith('/board/')">Board Members</span>
                            <span class="pointer" @click="goToListView" v-else="$route.path.startsWith('/shareholder/')">Share Holder</span>
                            <div v-if="$route.path.startsWith('/investors/createinvestors/cmpid/')">Add</div>
                            <div v-if="$route.path.startsWith('/bankers/createbankers/cmpid/')">Add</div>
                            <div v-if="$route.path.startsWith('/suppliers/createsuppliers/cmpid/')">Add</div>
                            <div v-if="$route.path.startsWith('/board/createboard/cmpid/')">Add</div>
                            <div v-if="$route.path.startsWith('/shareholder/createshareholder/cmpid/')">Add</div>
                            <div v-if="$route.path.startsWith('/investors/editinvestors/cmpid/')">Edit</div>
                            <div v-if="$route.path.startsWith('/bankers/editbankers/cmpid/')">Edit</div>
                            <div v-if="$route.path.startsWith('/suppliers/editsuppliers/cmpid/')">Edit</div>
                            <div v-if="$route.path.startsWith('/board/editboard/cmpid/')">Edit</div>
                            <div v-if="$route.path.startsWith('/shareholder/editshareholder/cmpid/')">Edit</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pl-2">
                <div class="create-company-container w-100 mt-2 pb-15">
                    <div class="create-company-header d-flex align-items-center justify-content-between">
                        <span class="fs-14 pl-8 text-white">General Information</span>
                        <span class="pointer" @click="expandGeneralTabs">
                            <i class="text-white" :class="{'icon-chevron-double-up':general_expand, 'icon-chevron-double-down':!general_expand}" style="font-size: 20px;"></i>
                        </span>
                    </div>
                    <div v-if="general_expand" class="px-10">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                <div class="w-100 position-relative">
                                    <span class="input-label">Group Name<span class="pl-1 text-danger">*</span></span>
                                    <div class="d-flex align-items-center mt-2">
                                        <input type="text" maxlength="100" class="input-field" v-validate="'required'"
                                            v-model="investors_info.groupname" placeholder="Enter Group Name"
                                            name="group_name" />
                                    </div>
                                    <div class="text-right position-absolute" style="right: 0;">
                                        <span
                                            class="input-field-length">{{ investors_info.groupname.length }}/100</span>
                                    </div>
                                    <span class="invalid-feedback-form text-danger" v-show="errors.has('group_name')">
                                        Group Name is Required
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-6 col-sm-12 mt-6">
                                <div class="w-100">
                                    <label class="input-label">Description</label>
                                    <textarea rows="10" type="text" maxlength="1000" class="input-field input-textarea" v-model="investors_info.description" name="description" placeholder="Enter Description" style="resize: none;"/>
                                    <div class="text-right">
                                        <span class="input-field-length">{{1000 - investors_info.description.length }}/1000</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                                <div class="w-100 position-relative">
                                    <span class="input-label">Investors Type<span class="pl-1 text-danger">*</span></span>
                                    <div class="w-100" style="font-weight:600;position: relative;">
                                        <div>
                                            <div class="form-check form-check-inline mt-2 mr-2">
                                                <label class="form-check-label">
                                                    <input class="form-check-input" type="radio" v-validate="'required'" v-model="investors_info.investorstype" name="Investors_type" value="Banker">
                                                    <span class="radio-icon"></span>
                                                    <span class="form-check-description">Banker</span>
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline mt-2" style="position: relative;">
                                                <label class="form-check-label">
                                                    <input class="form-check-input" type="radio" v-model="investors_info.investorstype" v-validate="'required'" name="Investors_type" value="Supplier">
                                                    <span class="radio-icon"></span>
                                                    <span class="form-check-description">Supplier</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="invalid-feedback-form text-danger" v-show="errors.has('Investors_type')">
                                        Investors Type is Required
                                    </span>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="create-company-header d-flex align-items-center justify-content-between mt-6">
                        <span class="fs-14 pl-8 text-white">Member Information</span>
                        <span class="pointer" @click="expandMembersTabs">
                            <i class="text-white" :class="{'icon-chevron-double-up':member_expand, 'icon-chevron-double-down':!member_expand}" style="font-size: 20px;"></i>
                        </span>
                    </div>
                    <div v-if="member_expand" class="px-4">
                        <div class="member-card mt-5 px-5 py-10" v-for="(members, dat_index) in members_data" :key="dat_index">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="input-label"></div>
                                <div style="margin-top: -42px;margin-right: -21px;">
                                    <span class="remove-box" @click="showDeleteComp(dat_index, members)">
                                        <img src="/static/images/remove-circle.svg" alt="" style="width:20px">
                                    </span>
                                </div>
                            </div>
                            <div class="members-data d-flex align-items-center pb-6 pr-8" style="margin-top: -20px;">
                                <span class="text-black fw-600 mr-3" style="width: max-content;min-width: max-content;">Member {{dat_index + 1}}</span>
                                <div class="inner-line-hr w-100"></div>
                                <img class="pointer members-data-rotate" :class="{'active-members-data-rotate-rotate':members.show_hide_member}" @click="showHideMembers(dat_index)" src="/static/images/round-arrow.svg" width="20" height="20">
                            </div>
                            <div v-if="members.show_hide_member">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12 mt-6">
                                        <div class="w-100 position-relative">
                                            <span class="input-label">Personal Email ID<span class="pl-1 text-danger">*</span></span>
                                            <div class="d-flex align-items-center mt-2">
                                                <input type="text" maxlength="100" class="input-field" v-validate="'required|email'"
                                                    v-model="members.personal_email" placeholder="Enter Personal Email ID"
                                                    :name="'personal_email_id'+dat_index" />
                                            </div>
                                            <div class="text-right position-absolute" style="right: 0;">
                                                <span
                                                    class="input-field-length">{{ members.personal_email.length }}/100</span>
                                            </div>
                                            <span class="invalid-feedback-form text-danger" v-show="errors.has('personal_email_id'+dat_index)">
                                                Personal Email ID is Required
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 mt-6">
                                        <div class="w-100 position-relative">
                                            <span class="input-label">Login Email ID<span class="pl-1 text-danger">*</span></span>
                                            <div class="d-flex align-items-center mt-2">
                                                <div class="position-relative w-100">
                                                    <div class="d-flex align-items-center">
                                                        <input type="text" maxlength="20" class="input-field" v-validate="{required: true,regex: /^[a-zA-Z0-9-_.]+$/}"
                                                            v-model="members.login_email" placeholder="Enter Login Email ID" :disabled="$route.path.startsWith('/investors/editinvestors/cmpid/') && !member_empty"
                                                            :name="'login_email_id'+dat_index" @change="checkEmail(dat_index)"/>
                                                    </div>
                                                    <div class="text-right position-absolute" style="right: 0;">
                                                        <span class="input-field-length">{{ members.login_email.length }}/20</span>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center mx-2">
                                                    <span>@</span>
                                                </div>
                                                <div class="d-flex align-items-center" style="width: 100% !important;">
                                                    <input type="text" maxlength="100" :disabled="true" :readonly="true" class="input-field"
                                                        v-model="members.company_domain" placeholder="Enter"
                                                    />
                                                </div>
                                            </div>
                                            <span v-if="check_email.status_id == 0" class="invalid-feedback-form text-danger">
                                                Login Id Already Exist
                                            </span>
                                            <!-- <span v-if="members.check_email == 1" class="invalid-feedback-form text-danger">
                                                
                                            </span> -->
                                            <span class="invalid-feedback-form text-danger" v-show="errors.has('login_email_id'+dat_index)">
                                                Login Email ID is Required
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 mt-6">
                                        <div class="w-100 position-relative">
                                            <span class="input-label">First Name<span class="pl-1 text-danger">*</span></span>
                                            <div class="d-flex align-items-center mt-2">
                                                <input type="text" maxlength="100" class="input-field" v-validate="'required'"
                                                    v-model="members.first_name" placeholder="Enter First Name"
                                                    :name="'first_name'+dat_index" />
                                            </div>
                                            <div class="text-right position-absolute" style="right: 0;">
                                                <span
                                                    class="input-field-length">{{ members.first_name.length }}/100
                                                </span>
                                            </div>
                                            <span class="invalid-feedback-form text-danger" v-show="errors.has('first_name'+dat_index)">
                                            First Name is Required
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 mt-6">
                                        <div class="w-100 position-relative">
                                            <span class="input-label">Last Name<span class="pl-1 text-danger">*</span></span>
                                            <div class="d-flex align-items-center mt-2">
                                                <input type="text" maxlength="100" class="input-field" v-validate="'required'"
                                                    v-model="members.last_name" placeholder="Enter Last Name"
                                                    :name="'last_name'+dat_index" />
                                            </div>
                                            <div class="text-right position-absolute" style="right: 0;">
                                                <span
                                                    class="input-field-length">{{ members.last_name.length }}/100
                                                </span>
                                            </div>
                                            <span class="invalid-feedback-form text-danger" v-show="errors.has('last_name'+dat_index)">
                                                Last Name is Required
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 mt-6">
                                        <div class="w-100 position-relative">
                                            <span class="input-label">ADD PRODUCT PERMISSION</span>
                                            <div class="d-flex align-items-center mt-6">
                                                <table class="tables table-new-template table-brand py-2">
                                                    <thead class="text-left">
                                                        <tr> 
                                                            <th></th>
                                                            <th class="input-label" width="30%">PRODUCTS</th>
                                                            <th></th>
                                                            <th></th>
                                                            <th class="input-label" width="30%">PERMISSION</th>
                                                            <th class="input-label text-center" width="30%">PRODUCT ACCESS</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-if="!product_loader && members.products.length == 0" class="text-center font-weight-bold">
                                                            <td colspan="7" class="text-center fw-500 py-3">No Products Found</td>
                                                        </tr>
                                                        <tr v-else class="sent-call-tr" style="background-color: #ecf1f9 !important;" v-for="(product, pr_index) in members.products" :key="pr_index">
                                                            <template v-if="product.product_name == 'DigiCloud' || product.product_name == 'Cloud'">
                                                                <td></td>
                                                                <td class="py-3 pl-2">Cloud</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td class="py-3 pl-2">{{product.role_id.name}}
                                                                    <!-- <div class="w-100">
                                                                        <div class="ml-auto mr-auto" style="position: relative; justify-content: left;">
                                                                            <div class="d-flex align-items-center" style="margin-top: .7rem !important;">
                                                                                <multiselect class="diginew-multiselect diginew-multiselectnodrop input-field-underlines item" :searchable="true" label="name" placeholder="Select Permission"
                                                                                :blockKeys="['Delete']" track-by="name" :options="permission_type" :name="'permission'+dat_index" v-validate="'required'" @open="getProductRoles(product.product_id, pr_index, dat_index)" :loading="product.product_roles_loading" :show-labels="false" v-model="product.role_id" style="background-color: #ecf1f9 !important;width: 250px !important;padding-bottom: 5px !important;"
                                                                                >
                                                                                    <template slot="noOptions">
                                                                                        <div class="text-secondary text-center fw-600">Manager List is not found</div>
                                                                                    </template>
                                                                                    <template slot="noResult">
                                                                                        <div class="text-secondary text-center fw-600">No Results Found</div>
                                                                                    </template>
                                                                                </multiselect>
                                                                            </div>
                                                                        </div>
                                                                    </div>  -->
                                                                </td>
                                                                <td class="text-center">
                                                                    <span class="switch switch-lg mr-2" style="top: 4px;">
                                                                        <el-switch @change="changeAcess(product, dat_index, pr_index)"
                                                                            active-color="#00448b"
                                                                            inactive-color="#bcb8b8"
                                                                            v-model ="product.has_access"
                                                                            class="routers">
                                                                        </el-switch> 
                                                                    </span>
                                                                </td>
                                                            </template>
                                                            <template v-if="product.product_name == 'DigiCRM' || product.product_name == 'CRM'">
                                                                <td></td>
                                                                <td class="py-3 pl-2">CRM</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td class="py-3 pl-2">{{product.role_id.name}}
                                                                    <!-- <div class="w-100">
                                                                        <div class="ml-auto mr-auto" style="position: relative; justify-content: left;">
                                                                            <div class="d-flex align-items-center" style="margin-top: .7rem !important;">
                                                                                <multiselect class="diginew-multiselect diginew-multiselectnodrop input-field-underlines item" :searchable="true" label="name" placeholder="Select Permission"
                                                                                :blockKeys="['Delete']" track-by="name" :options="permission_type" :name="'permission'+dat_index" v-validate="'required'" @open="getProductRoles(product.product_id, pr_index, dat_index)" :loading="product.product_roles_loading" :show-labels="false" v-model="product.role_id" style="background-color: #ecf1f9 !important;width: 250px !important;padding-bottom: 5px !important;"
                                                                                >
                                                                                    <template slot="noOptions">
                                                                                        <div class="text-secondary text-center fw-600">Manager List is not found</div>
                                                                                    </template>
                                                                                    <template slot="noResult">
                                                                                        <div class="text-secondary text-center fw-600">No Results Found</div>
                                                                                    </template>
                                                                                </multiselect>
                                                                            </div>
                                                                        </div>
                                                                    </div>  -->
                                                                </td>
                                                                <td class="text-center">
                                                                    <span class="switch switch-lg mr-2" style="top: 4px;">
                                                                        <el-switch @change="changeAcess(product, dat_index, pr_index)"
                                                                            active-color="#00448b"
                                                                            inactive-color="#bcb8b8"
                                                                            v-model ="product.has_access"
                                                                            class="routers">
                                                                        </el-switch> 
                                                                    </span>
                                                                </td>
                                                            </template>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4 text-right">
                            <button v-if="add_button_disable" :disabled="members_data.length == members_data_count"class="add-member-button text-white ml-3" @click="AddMember" style="border-radius: 6px !important;">+
                                Add New Member
                            </button>
                            <button v-else :disabled="members_data.length > 9"class="add-member-button text-white ml-3" @click="AddMember" style="border-radius: 6px !important;">+
                                Add New Member
                            </button>
                        </div>
                        <div class="mt-10 mb-10">
                            <div class="d-flex align-items-center justify-content-center">
                                <button class="btn btn-outline-secondary" @click="cancel" style="width: 120px;border-radius: 5px;">Cancel</button>
                                <button class="btn btn-new-success ml-5" @click="SaveInvestors" style="width: 120px;border-radius: 5px;" :disabled="save_create_btn_hide || fields_not_changed">Save
                                    <hollow-dots-spinner v-if="save_create_btn_hide" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <error-message-popup v-if="error_message" modal_name='personal_email_error' @hideDeleteError="hideDeleteError"></error-message-popup>
        <error-message-login v-if="error_login" modal_name='login_email_error' @hideLoginError="hideLoginError"></error-message-login>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
        <delete-investors-modal :group_type="group_type" v-if="delete_investors" @hideDeleteInvestorsModal="hideDeleteInvestorsModal" @deleteInvestorsSuccess="deleteMemberData" modal_name='delete_investors_modal' ></delete-investors-modal>
    </div>
</template>
<script>
    import companies from '../mixins/companies';
    import product from '../mixins/product';    
    import {HalfCircleSpinner} from 'epic-spinners';
    import consultant from '../mixins/consultant';
    import investors from '../mixins/investors';
    import { SweetModal } from 'sweet-modal-vue';
    import ErrorMessagePopup from './ErrorMessagePopup.vue';  
    import ErrorMessageLogin from './ErrorMessageLogin.vue'; 
    import DeleteInvestorsModal from './DeleteInvestorsModal.vue';  
    export default {
        mixins: [consultant, investors, companies, product],
        data() {
            return{
                warning_msg: "",
                success_msg: "",
                general_expand: true,
                member_expand: true,
                show_hide_member: true,
                save_create_btn_hide: false,
                permission_type: [],
                skip: 0,
                limit: 10,
                investors_info: {
                    groupname: "",
                    description: "",
                    investorstype: ""
                },
                members_data: [{
                    personal_email: "",
                    login_email: '',
                    company_domain: '',
                    first_name: "",
                    last_name: "",
                    start_date:"",
                    start_time: "",
                    end_date: "",
                    end_time: "",
                    company_id: this.$route.params.company_id,
                    show_hide_member: true,
                    products: [
                        // {
                        //     has_access: false,
                        //     product_id: "",
                        //     role_id: ""
                        // }
                    ],
                }],
                stringified_response: "",
                ajax_call_in_progress: false,
                product_loader: false,
                check_email: [],
                search_key: "",
                product_data_list: [],
                member_empty: false,
                isEditInvestors: false,
                email_duplicated: '',
                login_email: "",
                error_message: false,
                error_login: "",
                roleID : "",
                roleName: "",
                roleIDCRM : "",
                roleNameCRM: "",
                productIdCRM: "",
                productIdCloud: "",
                add_button_disable: false,
                members_data_count: null,
                delete_investors: false,
                investors_delete_id: "",
                group_type: ""
            }
        },
        components: {
            HalfCircleSpinner,
            SweetModal,
            ErrorMessagePopup,
            ErrorMessageLogin,
            DeleteInvestorsModal
        },
        methods: {
            changeAcess(product, member_index, pr_index){
                if(product.product_name == 'CRM' && product.has_access == true || product.product_name == 'DigiCRM' && product.has_access == true){
                    this.members_data[member_index].products[pr_index].role_id = {
                        id: this.roleIDCRM,
                        name: this.roleNameCRM
                    }
                }
                if(product.product_name == 'Cloud' && product.has_access == true || product.product_name == 'DigiCloud' && product.has_access == true){
                    this.members_data[member_index].products[pr_index].role_id = {
                        id: this.roleID,
                        name: this.roleName
                    }
                }
                if(product.product_name == 'CRM' && product.has_access == false || product.product_name == 'DigiCRM' && product.has_access == false){
                    this.members_data[member_index].products[pr_index].role_id = this.roleIDCRM
                }
                if(product.product_name == 'Cloud' && product.has_access == false || product.product_name == 'DigiCloud' && product.has_access == false){
                    this.members_data[member_index].products[pr_index].role_id = this.roleID
                }

            },
            showLoginError() {
                this.error_login = true;
                setTimeout(() => {
                    this.$modal.show('login_email_error');
                }, 500);
            },
            hideLoginError() {
                this.error_login = false;
            },
            showPersonalError() {
                this.error_message = true;
                setTimeout(() => {
                    this.$modal.show('personal_email_error');
                }, 500);
            },
            hideDeleteError() {
                this.error_message = false;
            },
            async checkEmail(index) {
                let params = {
                    "login_email": this.members_data[index].login_email+'@'+this.members_data[index].company_domain.replace(/.+\/\/|www.|\+/g, '')
                };
                this.check_email = await this.checkEmailExit(params);
            },
            async getProductRolesCRM(id, pr_index, index) {
                // this.members_data[index].products[pr_index].product_roles_loading = true;
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        search_key: this.search_key ? this.search_key : '',
                        product_id: id,
                    };
                    let response = await this.getListProductRole(params);
                    if(response.status_id == 1) {
                        this.permission_type = response.response;
                    }
                    // this.members_data[index].products[pr_index].product_roles_loading = false;
                    //type 1
                    this.permission_type.forEach((permissionData, pr_index) => {
                        
                        if (permissionData.name == "Sales Manager") {
                            if(this.$route.path.startsWith('/investors/createinvestors/cmpid/') || this.$route.path.startsWith('/bankers/createbankers/cmpid/') || this.$route.path.startsWith('/suppliers/createsuppliers/cmpid/')) {
                                this.members_data[0].products.forEach((prdata, index) => {
                                    if(prdata.product_name == 'CRM'){
                                        this.members_data[0].products[index].role_id.id = permissionData.id
                                        this.members_data[0].products[index].role_id.name = permissionData.name
                                    }
                                })
                            }else if(this.members_data.length == 1) {
                                this.members_data[0].products.forEach((prdata, index) => {
                                    if(prdata.product_name == 'CRM'){
                                        this.members_data[0].products[index].role_id.id = permissionData.id
                                        this.members_data[0].products[index].role_id.name = permissionData.name
                                    }
                                })
                            }
                        //type2
                        this.roleIDCRM = permissionData.id
                        this.roleNameCRM = permissionData.name
                        this.productIdCRM = id
                       }
                    })
                    // this.permission_type.forEach((data, index) => {
                    //     if (data.name == "Sales Manager") {
                    //         this.members_data[0].products[1].role_id.id = data.id
                    //         this.members_data[0].products[1].role_id.name = data.name
                    // //type2
                    //     this.roleIDCRM = data.id
                    //     this.roleNameCRM = data.name
                    //    }
                    // })
                    
                }
                catch(err) {
                    console.log(err);
                    // this.members_data[index].products[pr_index].product_roles_loading = false;
                }
            },
            async getProductRoles(id, pr_index, index) {
                // this.members_data[index].products[pr_index].product_roles_loading = true;
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        search_key: this.search_key ? this.search_key : '',
                        product_id: id,
                    };
                    let response = await this.getListProductRole(params);
                    if(response.status_id == 1) {
                        this.permission_type = response.response;
                    }
                    // this.members_data[index].products[pr_index].product_roles_loading = false;
                    //type 1
                    this.permission_type.forEach((permissionData, pr_index) => {
                        if (permissionData.name == "External User") {
                            if(this.$route.path.startsWith('/investors/createinvestors/cmpid/') || this.$route.path.startsWith('/bankers/createbankers/cmpid/') || this.$route.path.startsWith('/suppliers/createsuppliers/cmpid/')) {
                                this.members_data[0].products.forEach((prdata, index) => {
                                    if(prdata.product_name == 'DigiCloud'){
                                        this.members_data[0].products[index].role_id.id = permissionData.id
                                        this.members_data[0].products[index].role_id.name = permissionData.name
                                    }
                                })
                            }else if(this.members_data.length == 1) {
                                this.members_data[0].products.forEach((prdata, index) => {
                                    if(prdata.product_name == 'DigiCloud'){
                                        this.members_data[0].products[index].role_id.id = permissionData.id
                                        this.members_data[0].products[index].role_id.name = permissionData.name
                                    }
                                })
                            }
                            //type2
                            this.roleID = permissionData.id
                            this.roleName = permissionData.name
                            this.productIdCloud = id
                        }
                    })
                    // this.permission_type.forEach((data, index) => {
                    //     if (data.name == "Admin") {
                    //         this.members_data[0].products[0].role_id.id = data.id
                    //         this.members_data[0].products[0].role_id.name = data.name
                    // //type2
                    //     this.roleID = data.id
                    //     this.roleName = data.name
                    //    }
                    // })

                    
                }
                catch(err) {
                    console.log(err);
                    // this.members_data[index].products[pr_index].product_roles_loading = false;
                }
            },
            async getSubscribedProducts() {
                this.product_data_list = []
                this.product_loader = true;
                try {
                    let params = {
                        company_id: this.$route.params.company_id,
                        search_key: this.search_key
                    };
                    let response = await this.getCompaniesSubscribedProducts(params);
                    if(response.status_id == 1) {
                        let prod_data = response.products_list;
                        prod_data.forEach((data, index) => {
                            if(this.isEditInvestors == true){
                            this.product_data_list.push({
                                    has_access: true,
                                    product_id: data.id,
                                    product_name: data.product_code,
                                    role_id: {
                                        id: "",
                                        name: "",
                                    },
                                    product_roles_loading: false
                                })
                            }else{
                                this.product_data_list.push({
                                        has_access: true,
                                        product_id: data.id,
                                        product_name: data.product_code,
                                        role_id: {
                                            id: "",
                                            name: "",
                                        },
                                        product_roles_loading: false
                                    })
                                this.members_data.forEach(x => {
                                    x.products.push({
                                        has_access: true,
                                        product_id: data.id,
                                        product_name: data.product_code,
                                        role_id: {
                                            id: "",
                                            name: "",
                                        },
                                        product_roles_loading: false
                                    })
                                })
                            }
                        })
                        // this.getProductRoles(prod_data[0].id);
                        // this.getProductRolesCRM(prod_data[1].id);
                        prod_data.forEach((data, index) => {
                            if(data.product_name == 'Cloud'){
                                this.getProductRoles(data.id);
                            }else if(data.product_name == 'CRM'){
                                this.getProductRolesCRM(data.id);
                            }
                        })
                    }
                    this.product_loader = false;
                }
                catch(err) {
                    console.log(err);
                }
            },
            hideSuccess() {
                setTimeout(() => {
                    this.$modal.hide(this.modal_name);
                }, 500);
                if(this.$route.path.startsWith('/bankers/')){
                    this.$router.push('/bankers/listview/cmpid/'+this.$route.params.company_id);
                }else if(this.$route.path.startsWith('/suppliers/')){
                    this.$router.push('/suppliers/listview/cmpid/'+this.$route.params.company_id);
                }else {
                    this.$router.push('/investors/listview/cmpid/'+this.$route.params.company_id);
                }
            },
            cancel() {
                if(this.$route.path.startsWith('/bankers/')){
                    this.$router.push('/bankers/listview/cmpid/'+this.$route.params.company_id);
                }else if(this.$route.path.startsWith('/suppliers/')){
                    this.$router.push('/suppliers/listview/cmpid/'+this.$route.params.company_id);
                }else {
                    this.$router.push('/investors/listview/cmpid/'+this.$route.params.company_id);
                }
            },
            SaveInvestors() {
                this.$validator.validateAll().then(result => {
                    if (result == true) {
                        let duplicate_data = this.hasDuplicateData(this.members_data)
                        if(duplicate_data == false){
                            this.SaveInvestorsTrue();
                        }
                        else{
                            if(this.email_duplicated){
                                this.showPersonalError();
                            }else if(this.login_email){
                                this.showLoginError()
                            }
                        }
                    }
                })
            },
            hasDuplicateData(arrayOfObjects, valueToCheck) {
                const seenValues = new Set();
                const seenValuesLogin = new Set();
                this.email_duplicated = ''
                this.login_email = ''
                for (const outerObject of arrayOfObjects) {
                    // Check the value in the outer object
                    const outerValue = outerObject.personal_email.toLowerCase();
                    const outerValueLogin = outerObject.login_email.toLowerCase();
                    if(outerObject.personal_email.length > 0) {
                        // Check if the value is already seen
                        if (seenValues.has(outerValue)) {
                            this.email_duplicated = outerValue
                            return true; // Duplicate found
                        }

                        seenValues.add(outerValue);
                    }if(outerObject.login_email.length > 0){
                        if (seenValuesLogin.has(outerValueLogin)) {
                            this.login_email = outerValueLogin
                            return true; // Duplicate found
                        }
                        seenValuesLogin.add(outerValueLogin);
                    }
                }

                return false; // No duplicates found
            },
            async SaveInvestorsTrue(){
                try {
                    this.save_create_btn_hide = true
                    if(this.$route.path.startsWith('/investors/createinvestors/cmpid/') || this.$route.path.startsWith('/bankers/createbankers/cmpid/') || this.$route.path.startsWith('/suppliers/createsuppliers/cmpid/')){
                        let temp_members_data = []
                        this.members_data.forEach(x => {
                            x.login_email= x.login_email+'@'+x.company_domain.replace(/.+\/\/|www.|\+/g, ''),
                            delete x['company_domain']
                            delete x['show_hide_member']
                            delete x['validate_permision']
                            let temp_products = [ ];
                            [...x.products].forEach((data, index) => {
                                if(data.role_id.id != '' && data.product_name == 'Cloud' || data.role_id.id != '' && data.product_name == 'DigiCloud') {
                                    temp_products.push({
                                        has_access: data.has_access,
                                        product_id: data.product_id,
                                        role_id: data.role_id.id
                                    });
                                }else if(data.role_id.id != '' && data.product_name == 'CRM' || data.role_id.id != '' && data.product_name == 'DigiCRM') {
                                    temp_products.push({
                                        has_access: data.has_access,
                                        product_id: data.product_id,
                                        role_id: data.role_id.id
                                    });
                                }
                            })
                            x.products = temp_products
                            x.products.forEach((data, index) => {
                                if(data.hasOwnProperty('product_roles_loading')) {
                                    delete data.product_roles_loading
                                }
                                delete data.product_name
                            })
                        })
                        let response = await this.createInvestors(this.create_data);
                        this.success_msg = response.message;
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.save_create_btn_hide = false;
                            this.hideSuccess();
                        }, 3000);
                        this.save_create_btn_hide = false;
                    }else if(this.$route.path.startsWith('/investors/editinvestors/cmpid/') || this.$route.path.startsWith('/bankers/editbankers/cmpid/') || this.$route.path.startsWith('/suppliers/editsuppliers/cmpid/')) {
                        this.members_data.forEach(x => {
                            x['employee_company_id'] = x.id
                            delete x['id'] 
                            delete x['role'] 
                            delete x['validate_permision']
                            delete x['show_hide_member']
                            x.login_email= x.login_email+'@'+x.company_domain
                            delete x['company_domain']
                            if(x.products.length != 0){
                                let temp_products = [ ];
                                [...x.products].forEach((data, index) => {
                                    if(data.id != '' && data.product_name == 'Cloud' && data.has_access == true  || data.id != '' && data.product_name == 'DigiCloud' && data.has_access == true){
                                        temp_products.push({
                                            has_access: data.has_access,
                                            id: data.id,
                                            product_id: data.product_id,
                                            product_name: data.product_code,
                                            role_id: data.role_id.id
                                        });
                                    } else if(data.id != '' && data.product_name == 'CRM' && data.has_access == true || data.id != '' && data.product_name == 'DigiCRM' && data.has_access == true) {
                                        temp_products.push({
                                            has_access: data.has_access,
                                            id: data.id,
                                            product_id: data.product_id,
                                            product_name: data.product_code,
                                            role_id: data.role_id.id
                                        });
                                    }else if(data.id == '' && data.product_name == 'Cloud' && data.has_access == true || data.id != '' && data.product_name == 'DigiCloud' && data.has_access == true){
                                        temp_products.push({
                                            has_access: data.has_access,
                                            product_id: this.productIdCloud,
                                            role_id: this.roleID
                                        })
                                    }else if(data.id == '' && data.product_name == 'CRM' && data.has_access ==  true || data.id != '' && data.product_name == 'DigiCRM' && data.has_access == true){
                                        temp_products.push({
                                            has_access: data.has_access,
                                            product_id: this.productIdCRM,
                                            role_id: this.roleIDCRM
                                        })
                                    }else if(data.id != '' && data.product_name == 'Cloud' && data.has_access == false || data.id != '' && data.product_name == 'DigiCloud' && data.has_access == false){
                                        temp_products.push({
                                            id:data.id,
                                            role_id: this.role_id,
                                            has_access: data.has_access,
                                            product_id: this.productIdCloud,
                                            product_name: data.product_name
                                        })
                                    }else if(data.id != '' && data.product_name == 'CRM' && data.has_access ==  false || data.id != '' && data.product_name == 'DigiCRM' && data.has_access == false){
                                        temp_products.push({
                                            id:data.id,
                                            role_id: this.role_id,
                                            has_access: data.has_access,
                                            product_id: this.productIdCRM,
                                            product_name: data.product_name
                                        })
                                    }
                                    x.products = temp_products
                                    // if(x.products[0].id != '' && x.products[1].id != '' ){
                                    //     x.products = [ {
                                    //         has_access:  x.products[0].has_access,
                                    //         product_id: x.products[0].product_id,
                                    //         id: x.products[0].id,
                                    //         product_name: x.products[0].product_code,
                                    //         role_id: x.products[0].role_id.id
                                    //     },
                                    //     {
                                    //         has_access:  x.products[1].has_access,
                                    //         product_id: x.products[1].product_id,
                                    //         id: x.products[1].id,
                                    //         product_name: x.products[1].product_code,
                                    //         role_id: x.products[1].role_id.id
                                    //     }]
                                    // }else{
                                    //         x.products = [ {
                                    //             has_access:  false,
                                    //             product_id: x.products[0].product_id,
                                    //             product_name: x.products[0].product_code,
                                    //             role_id: x.products[0].role_id.id
                                    //         }]
                                    //     }
                                    // }
                                })
                            }
                        })
                        let response = await this.editInvestors(this.create_data, this.$route.params.id);
                        this.success_msg = response.message;
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.save_create_btn_hide = false;
                            this.hideSuccess();
                        }, 3000);
                        this.save_create_btn_hide = false;
                    }
                }catch(error) {
                    this.warning_msg = error;
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                        this.save_create_btn_hide = false;
                        this.hideSuccess();
                    }, 3000);
                }
                this.save_create_btn_hide = false;
            },
            AddMember(){
                var tempProducts = []
                if(this.member_empty == true && this.members_data.length == 0 && this.$route.path.startsWith('/bankers/editbankers/cmpid/') || this.member_empty == true && this.members_data.length == 0 && this.$route.path.startsWith('/suppliers/editsuppliers/cmpid/') || this.member_empty == true && this.members_data.length == 0 && this.$route.path.startsWith('/investors/editinvestors/cmpid/')){
                    this.getSubscribedProducts();
                }
                // var productID = ""
                this.product_data_list.forEach((data, index) => {
                    if(data.product_name== 'DigiCloud'){
                        tempProducts.push({
                            has_access: true,
                            product_id: data.product_id,
                            product_name: data.product_name,
                            role_id: {
                                id: this.roleID,
                                name: this.roleName,
                            },
                            product_roles_loading: false
                        })
                    }else if(data.product_name == 'CRM'){
                        tempProducts.push({
                            has_access: true,
                            product_id: data.product_id,
                            product_name: data.product_name,
                            role_id: {
                                id: this.roleIDCRM,
                                name: this.roleNameCRM,
                            },
                            product_roles_loading: false
                        })
                    }
                    // productID =  data.product_id
                })
                // getProductRoles(productID, 0, 0)
                this.product_data_list = tempProducts
                this.members_data.push({
                    personal_email: "",
                    login_email: '',
                    company_domain: '',
                    first_name: "",
                    last_name: "",
                    start_date:"",
                    start_time: "",
                    end_date: "",
                    end_time: "",
                    show_hide_member: true,
                    company_id: this.$route.params.company_id,
                    products: this.product_data_list,
                })
                this.member_empty = true
                this.getCompData(this.$route.params.company_id);
            },
            hideDeleteInvestorsModal() {
                this.delete_investors = false;
            },
            showDeleteComp(index,data) {
                if(data.id == null){
                    this.members_data.splice(index, 1)
                }else{
                    this.investors_delete_id = data.id
                    this.delete_investors = true;
                    setTimeout(() => {
                        this.$modal.show('delete_investors_modal');
                    }, 500);
                }
                
            },
            async deleteMemberData() {
                let member_data_id = []
                member_data_id.push(this.investors_delete_id)
                try {
                    let params = {
                        id: member_data_id
                    }
                    let response = await this.deleteConsultant(params)
                    if(response.status_id == 1) {
                        this.success_msg = response.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                        }, 2000);
                        this.getInvestorsData(this.$route.params.id);
                    }
                }
                catch(err) {
                    // this.ajax_call_in_progress = false;
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                }
            },
            goToListView(){
                if(this.$route.path.startsWith('/bankers/')){
                    this.$router.push('/bankers/listview/cmpid/'+this.$route.params.company_id);
                }
                else if(this.$route.path.startsWith('/suppliers/')){
                    this.$router.push('/suppliers/listview/cmpid/'+this.$route.params.company_id);
                }else{
                    this.$router.push('/investors/listview/cmpid/'+this.$route.params.company_id);
                }
            },
            expandGeneralTabs() {
                this.general_expand = !this.general_expand;
            },
            expandMembersTabs() {
                this.member_expand = !this.member_expand;
            },
            showHideMembers(index) {
                if(this.members_data[index].show_hide_member == true){
                    this.members_data[index].show_hide_member = false
                    this.$forceUpdate();
                }else if(this.members_data[index].show_hide_member == false){
                    this.members_data[index].show_hide_member = true
                    this.$forceUpdate();
                }
                // this.show_hide_member = !this.show_hide_member
            },
            async getCompData(company_id) {
                try {
                    let response = await this.getCompanyById(company_id);
                    this.members_data.forEach(x => {
                        x.company_domain = response.response.domain;
                    });

                }
                catch (err) {
                }
            },
            async getInvestorsData(id){
                this.ajax_call_in_progress = true;
                try {
                    let response = await this.getInvestorsById(id);
                    let dat_by_id = response.response
                    if(response.status_id == 1) {
                        this.investors_info.groupname = dat_by_id.group_name
                        this.investors_info.description = dat_by_id.description
                        this.members_data = dat_by_id.investor_details
                        this.add_button_disable = true
                        let members_count = this.members_data.length
                        this.members_data_count = members_count + 10
                        if(this.members_data.length == 0){
                            this.member_empty = true
                            this.add_button_disable = true
                        }
                        this.members_data.forEach(x =>{
                            x['show_hide_member'] = true
                            var emailSplit = x.login_email.split("@")
                            x.login_email = emailSplit[0],
                            x.company_domain = emailSplit[1]
                        })
                        this.members_data.forEach(x =>{
                            x.products.forEach(y =>{
                                y.has_access = y.has_access,
                                y.id = y.id ? y.id : "",
                                y.product_id = y.product_id,
                                y.product_name = y.product_name,
                                y.role_id = {
                                    id: y.role_id,
                                    name: y.role_name,
                                },
                                y.product_roles_loading = false
                            })
                        })
                    }
                    this.ajax_call_in_progress = false;
                    this.stringified_response = JSON.stringify(this.create_data)
                }
                catch(err) {
                    this.ajax_call_in_progress = false;
                }
            }
        },
        created() {
            const dict = {
                custom: {
                    personal_email_id: {
                        required: () => "Personal Email is required",
                        email: () => "Enter a Valid Email",
                    },
                    login_email_id:{
                        required: () => "Login E-mail id is required",
                        regex: () => "Please enter valid Email Address",
                    },
                }
            };
            this.$validator.localize("en", dict);       
        },
        mounted() {
            if(this.$route.path.startsWith('/bankers/')){
                this.group_type = "banker"
            }else if(this.$route.path.startsWith('/suppliers/')){
                this.group_type = "supplier"
            }else if(this.$route.path.startsWith('/board/')){
                this.group_type = "board"
            }else if(this.$route.path.startsWith('/shareholder/')){
                this.group_type = "shareholder"
            }else{
                this.group_type = "investor"
            }
            this.getCompData(this.$route.params.company_id);
            if(this.$route.path.startsWith('/investors/editinvestors/cmpid/') || this.$route.path.startsWith('/bankers/editbankers/cmpid/') || this.$route.path.startsWith('/suppliers/editsuppliers/cmpid/') || this.$route.path.startsWith('/board/editboard/cmpid/') || this.$route.path.startsWith('/shareholder/editshareholder/cmpid/')) {
                this.getInvestorsData(this.$route.params.id);
                this.isEditInvestors = true
                    this.getSubscribedProducts();
            }else{
                this.getSubscribedProducts();
            }
        },
        computed: {
            fields_not_changed(){
                return this.stringified_response == JSON.stringify(this.create_data)
            },
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            create_data() {
                return {
                    "group_name": this.investors_info.groupname,
                    "description": this.investors_info.description,
                    "company_id": this.$route.params.company_id,
                    "group_type":  this.group_type,
                    "investor_details": this.members_data
                }
            }
        }
    }
</script>
<style>
    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
    }
    .member-card{
        border: 1px solid black;
        border-radius: 8px !important;
    }
    .create-company-container {
        background-color: #fff;
        padding: 15.5px 0 0px 0;
    }

    .create-company-container:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    .create-company-container:nth-child(2) {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    .create-company-container:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .members-data-rotate {
        transform: rotate(180deg);
        transition: all 0.3s;
    }
    .active-members-data-rotate-rotate {
        transform: unset;
    }
    .inner-line-hr{
        border: 0;
        width: 100%;
        border-top: 2px solid #bed1f8;
    }
    .table-brand{
        border:1px solid grey;
        border-radius: 10px !important;
    }
    .tables {
        width: 100%;
        max-width: 100%;
        margin-bottom: 0.4rem;
        background-color: transparent;
    }
    #table {
        display: table;
        border-collapse: separate;
        box-sizing: border-box;
        text-indent: initial;
        border-spacing: 2px;
        border-color: gray;
    }
    .tables thead th {
        border-bottom: 1px solid #E9ECEF;
        font-weight: 500;
    }
    .tables th, .table td {
        padding: 1rem 1.2rem;
        vertical-align: middle;
    }
    .tables th, .table td {
        padding: 0.8rem;
        vertical-align: top;
        border-top: 0px solid #E9ECEF;
    }
    .remove-box {
        width: 40px;
        height: 40px;
        margin: 0 0 6.5px 10.3px;
        padding: 10px 10px 10px 10px;
        border-radius: 6px;
        border: solid 0.5px #707070;
        background-color: var(--white);
    }
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .input-field-underlines{
        border: none !important;
        border-bottom: solid 0px #303031 !important;
        padding: 3px 10px;
        width: 100%;
        border-radius: 0px !important;
    }
    .btn-success:disabled{
        cursor: no-drop !important;
    }
    .add-member-button{
        cursor: pointer;
        border-radius: 6px !important;
        background-color: #00448b;
        height: 32px;
        padding: 0 18px;
        -webkit-border-radius: 18px !important;
        -moz-border-radius: 18px !important;
        border-radius: 18px;
        font-size: 12px;
        line-height: 32px;
        text-transform: none;
        font-weight: 600;
        -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0), 0px 2px 2px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0) !important;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0), 0px 2px 2px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0) !important;
    }
    .add-member-button:disabled{
        cursor: no-drop !important;
        color: rgba(0, 0, 0, 0.26) !important;
        background: rgba(0, 0, 0, 0.12) !important;
        opacity: 1;
    }
    .el-switch.is-disabled {
        opacity: none !important;
    }
</style>
